* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: 'Encode Sans Expanded', sans-serif;
  height: 100%;
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

p:not(:first-child) {
  margin: 1rem 0;
}

.u-text-center {
  text-align: center;
}

// Spacing classes
$props: (
        p: padding,
        m: margin
);

$sides: (
        t: top,
        b: bottom,
        l: left,
        r: right
);

$spacing: (
        0: 0,
        1: 0.25rem,
        2: 0.5rem,
        3: 0.75rem,
        4: 1rem,
        5: 1.25rem,
        6: 1.5rem,
        7: 2rem,
        8: 2.5rem,
        9: 3rem
);

@each $sp_key, $sp_val in $spacing {
  @each $prop_key, $prop_val in $props {
    .#{$prop_key}-#{$sp_key} {
      #{$prop_val}: #{$sp-val} !important;
    }
    @each $side_key, $side_val in $sides {
      .#{$prop_key}#{$side_key}-#{$sp_key} {
        #{$prop_val}-#{$side_val}: #{$sp-val} !important;
      }
    }
  }
}

//Override spacing for mobile size screens. Prefix spacing with i.e '.m-mt-1'
@media (max-width: 812px) {
  @each $sp_key, $sp_val in $spacing {
    @each $prop_key, $prop_val in $props {
      .m-#{$prop_key}-#{$sp_key} {
        #{$prop_val}: #{$sp-val} !important;
      }
      @each $side_key, $side_val in $sides {
        .m-#{$prop_key}#{$side_key}-#{$sp_key} {
          #{$prop_val}-#{$side_val}: #{$sp-val} !important;
        }
      }
    }
  }
}

.link {
  color: #0397f5;
  transition: text-decoration-color .15s;
  user-select: none;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

// UL list

ul {
  list-style-type: none;
  padding: 0 0 0 0.75rem;

  & > li {
    position: relative;
    margin: 0.5rem;
  }

  & > li:before {
    content: "\25A0";
    color: #bf872d;
    font-size: 16px;
    position: absolute;
    top: -2px;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}