.pswp-gallery {
  column-count: 4;
  column-gap: 0.25rem;
  min-height: 530px;

  a {
    margin: 0 0 0.25rem 0;
    display: grid;
    grid-template-rows: 1fr auto;
    break-inside: avoid;

    img {
      grid-row: 1 / -1;
      grid-column: 1;
    }
  }
}

img {
  max-width: 100%;
  display: block;
}

.img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;

  &--16-9 {
    padding-top: 56.25%;
  }

  &--1-1 {
    padding-top: 100%;
  }

  &--1-08 {
    padding-top: 125%;
  }

  &--5-1 {
    padding-top: 20%;
  }

  &--2-1 {
    padding-top: 50%;
  }

  &--2-3 {
    padding-top: 116%;
  }

  &--4-3 {
    padding-top: 75%;
  }

  &--3-2 {
    padding-top: 66%;
  }

  &--contain {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media (max-width: 812px) {
  .pswp-gallery {
    column-count: 1;
    column-gap: 0.25rem;

    a {
      width: 100%;
      height: 100%;

      &:nth-child(1) {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-end: 2;
      }
    }
  }
}

@keyframes fade-in-background-black {
  0% {
    background-color: rgba(#010606, 0)
  }
  100% {
    background-color: rgba(#010606, 1)
  }
}