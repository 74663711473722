.form {
  position: relative;

  .pending, .success, .fail {
    display: none;
  }

  &_pending {
    .pending {
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(#ffffff, 0.5);
      left: 0;
      top: 0;
      z-index: 1;

      .spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        border: 0.5rem solid #ffffff;
        border-top: 0.5rem solid #bf872d;
        border-bottom: 0.5rem solid #bf872d;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        transform: translate(-50%, -50%);
        color: #010606;
        animation: spin 2s linear infinite;
      }
    }
  }

  &_success {
    .success {
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      left: 0;
      top: 0;
      z-index: 1;

      & > .message {
        position: absolute;
        padding: 1rem;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &_fail {
    .fail {
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      left: 0;
      top: 0;
      z-index: 1;

      & > .message {
        position: absolute;
        padding: 1rem;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

// INPUTS
.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.input-container {
  position: relative;
  border-bottom: 2px solid #dbdbdb;
  margin: 1.5rem;

  &.full {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.input-container-button {
  grid-column-start: 2;
  grid-column-end: 3;

  button {
    float: right;
  }
}

input, textarea {
  display: block;
  width: 100%;
  height: 40px;
  background: transparent;
  font-size: 1rem;
  color: #555555;
  line-height: 1.2;
  padding: 0 2px;
  outline: none;
  border: none;
}

input:focus + .input-focus:before, textarea:focus + .input-focus:before {
  width: 100%;
}

button {
  text-decoration: none;
  border-radius: 0.5rem;
  background: #bf872d;
  white-space: nowrap;
  padding: 12px 30px;
  color: #fff;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border: 1px solid #bf872d;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    color: #010606;
  }
}

textarea {
  min-height: 3rem;
}

.input-focus:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  background: #bf872d;
  background: linear-gradient(45deg, #bf872d, #c07804);
}

.input-focus {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

@media (max-width: 812px) {
  .inputs {
    display: block;
    margin-top: 3rem;
  }
  .input-container-button {
    display: block;
    margin-top: 3rem !important;

    button {
      width: 100%;
      float: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}